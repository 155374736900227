<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="employeeList" class="form-label">* Empleado Afectado</label>
        <select
          name="employeeList"
          class="form-select"
          v-model="employee"
          required
        >
          <option
            v-for="employee in employeeList"
            v-bind:value="employee._id"
            :key="employee._id"
            >{{ employee.name }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="employeeList" class="form-label"
          >* Jornada en que Sucede</label
        >
        <select name="workday" class="form-select" v-model="workday" required>
          <option
            v-for="workday in workdayList"
            v-bind:value="workday"
            :key="workday"
            >{{ workday.toLowerCase().replace("_", " ") }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="accidentDate" class="form-label"
          >* Fecha del Accidente</label
        >
        <input
          type="date"
          class="form-control"
          name="accidentDate"
          v-model="accidentDate"
          required
        />
      </div>
      <div class="mb-3">
        <label for="accidentTime" class="form-label"
          >* Hora del Accidente</label
        >
        <input
          type="time"
          class="form-control"
          name="accidentTime"
          v-model="accidentTime"
          required
        />
      </div>
      <div class="mb-3">
        <label for="daysOfDisability" class="form-label"
          >* Días de Incapacidad</label
        >
        <input
          type="number"
          class="form-control"
          name="daysOfDisability"
          v-model="daysOfDisability"
          max="999"
          min="0"
          required
        />
      </div>
      <div class="mb-3">
        <label for="PCL" class="form-label"
          >* Procentaje de Pérdida de Capacidad Laboral (PCL)</label
        >
        <input
          type="number"
          class="form-control"
          name="PCL"
          v-model="PCL"
          max="100"
          min="0"
          step=".01"
          required
        />
      </div>
      <div class="d-flex justify-content-end">
        <button
          @click.prevent="next('forward')"
          class="btn btn-secondary shadow-secondary"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      employeeList: [],
      employee: "",
      workday: "",
      workdayList: [],
      accidentDate: "",
      accidentTime: "",
      daysOfDisability: "",
      PCL: ""
    };
  },
  mounted() {
    store.getData("employee").then(employeeList => {
      employeeList.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      employeeList.forEach(employee => {
        if (employee.branch === this.$route.params._id) {
          this.employeeList.push(employee);
        }
      });
    });
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "accident") {
          this.workdayList = schema.data.workday.enum;
        }
      });
      this.mounted = true;
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.employee !== undefined && val.employee !== null) {
              this.employee = val.employee;
            }
            if (val.workday !== undefined && val.workday !== null) {
              this.workday = val.workday;
            }
            if (val.accidentDate !== undefined && val.accidentDate !== null) {
              const d = new Date(val.accidentDate);
              const year = d.getFullYear();
              let month = d.getMonth() + 1;
              if (month < 10) {
                month = "0" + month.toString();
              }
              let day = d.getDate();
              if (day < 10) {
                day = "0" + day.toString();
              }
              this.accidentDate = year + "-" + month + "-" + day;
            }
            if (val.accidentTime !== undefined && val.accidentTime !== null) {
              this.accidentTime = val.accidentTime;
            }
            if (
              val.daysOfDisability !== undefined &&
              val.daysOfDisability !== null
            ) {
              this.daysOfDisability = val.daysOfDisability;
            }
            if (val.PCL !== undefined && val.PCL !== null) {
              this.PCL = val.PCL;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Employee AT Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
