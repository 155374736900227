<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="accidentPlace" class="form-label"
          >* Lugar del Accidente</label
        >
        <input
          type="text"
          class="form-control"
          name="accidentPlace"
          :minlength="accidentPlaceMin"
          :maxlength="accidentPlaceMax"
          v-model="accidentPlace"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese un lugar de accidente
        </div>
      </div>
      <div class="mb-3">
        <label for="injuryType" class="form-label">* Tipo de Lesión</label>
        <select
          name="injuryType"
          class="form-select"
          v-model="injuryType"
          required
        >
          <option
            v-for="injuryType in injuryTypeList"
            v-bind:value="injuryType"
            :key="injuryType"
            >{{ injuryType.toLowerCase().replaceAll("_", " ") }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="bodyPart" class="form-label"
          >* Parte del Cuerpo Afectada</label
        >
        <select name="bodyPart" class="form-select" v-model="bodyPart" required>
          <option
            v-for="bodyPart in bodyPartList"
            v-bind:value="bodyPart"
            :key="bodyPart"
            >{{ bodyPart.toLowerCase().replaceAll("_", " ") }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="accidentAgent" class="form-label"
          >* Agente del Accidente</label
        >
        <select
          name="accidentAgent"
          class="form-select"
          v-model="accidentAgent"
          required
        >
          <option
            v-for="accidentAgent in accidentAgentList"
            v-bind:value="accidentAgent"
            :key="accidentAgent"
            >{{ accidentAgent.toLowerCase().replaceAll("_", " ") }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="accidentMechanism" class="form-label"
          >* Mecanismo o Forma del Accidente</label
        >
        <select
          name="accidentMechanism"
          class="form-select"
          v-model="accidentMechanism"
          required
        >
          <option
            v-for="accidentMechanism in accidentMechanismList"
            v-bind:value="accidentMechanism"
            :key="accidentMechanism"
            >{{ accidentMechanism.toLowerCase().replaceAll("_", " ") }}</option
          >
        </select>
      </div>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-primary shadow-primary"
          @click.prevent="submit()"
        >
          {{ submitName }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      accidentPlace: "",
      accidentPlaceMin: 0,
      accidentPlaceMax: 1,
      injuryType: "",
      injuryTypeList: [],
      bodyPart: "",
      bodyPartList: [],
      accidentAgent: "",
      accidentAgentList: [],
      accidentMechanism: "",
      accidentMechanismList: []
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "accident") {
          this.accidentPlaceMin = schema.data.accidentPlace.min;
          this.accidentPlaceMax = schema.data.accidentPlace.max;
          this.injuryTypeList = schema.data.injuryType.enum;
          this.bodyPartList = schema.data.bodyPart.enum;
          this.accidentAgentList = schema.data.accidentAgent.enum;
          this.accidentMechanismList = schema.data.accidentMechanism.enum;
        }
      });
      this.mounted = true;
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.accidentPlace !== undefined && val.accidentPlace !== null) {
              this.accidentPlace = val.accidentPlace;
            }
            if (val.injuryType !== undefined && val.injuryType !== null) {
              this.injuryType = val.injuryType;
            }
            if (val.bodyPart !== undefined && val.bodyPart !== null) {
              this.bodyPart = val.bodyPart;
            }
            if (val.accidentAgent !== undefined && val.accidentAgent !== null) {
              this.accidentAgent = val.accidentAgent;
            }
            if (
              val.accidentMechanism !== undefined &&
              val.accidentMechanism !== null
            ) {
              this.accidentMechanism = val.accidentMechanism;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Employee AT Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
