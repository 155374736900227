<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="description" class="form-label"
          >* Descripción del Accidente</label
        >
        <textarea
          class="form-control"
          name="description"
          rows="3"
          :minlength="descriptionMin"
          :maxlength="descriptionMax"
          required
          v-model="description"
        ></textarea>
        <div class="invalid-feedback">
          {{ errorMsg }}
        </div>
      </div>
      <div class="mb-3">
        <label for="insideTheCompany" class="form-label"
          >* ¿El Accidente Ocurre Dentro de la Empresa?</label
        >
        <select
          name="insideTheCompany"
          class="form-select"
          v-model="insideTheCompany"
          required
        >
          <option :value="true">SÍ</option>
          <option :value="false">NO</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="accidentType" class="form-label">* Tipo de Accidente</label>
        <select
          name="accidentType"
          class="form-select"
          v-model="accidentType"
          required
        >
          <option
            v-for="accidentType in accidentTypeList"
            v-bind:value="accidentType"
            :key="accidentType"
            >{{ accidentType.toLowerCase().replaceAll("_", " ") }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="causedDeath" class="form-label"
          >* ¿Causó la Muerte al trabajador?</label
        >
        <select
          name="causedDeath"
          class="form-select"
          v-model="causedDeath"
          required
        >
          <option :value="true">SÍ</option>
          <option :value="false">NO</option>
        </select>
      </div>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('forward')"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      description: "",
      descriptionMin: 0,
      descriptionMax: 1,
      insideTheCompany: "",
      accidentType: "",
      accidentTypeList: [],
      causedDeath: ""
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "accident") {
          this.descriptionMin = schema.data.description.min;
          this.descriptionMax = schema.data.description.max;
          this.accidentTypeList = schema.data.accidentType.enum;
        }
      });
      this.mounted = true;
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.description !== undefined && val.description !== null) {
              this.description = val.description;
            }
            if (
              val.insideTheCompany !== undefined &&
              val.insideTheCompany !== null
            ) {
              this.insideTheCompany = val.insideTheCompany;
            }
            if (val.accidentType !== undefined && val.accidentType !== null) {
              this.accidentType = val.accidentType;
            }
            if (val.causedDeath !== undefined && val.causedDeath !== null) {
              this.causedDeath = val.causedDeath;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Employee AT Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
